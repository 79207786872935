<template>
  <div class="bgColor">
    <topNav title="消費記錄" :border="true"></topNav>
    <ul
      class="list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
    >
      <li
              v-if="rechangeList&&rechangeList.length > 0"
        v-for="item in rechangeList"
        class="clearfix"
        :key="item.id"
        @click="goone(item.consumeTypeValue)"
      >
        <div class="left fl">
          <p class="type ellipsis">{{ item.consumeType }}</p>
          <p class="time">{{ item.lastCreateTime }}</p>
        </div>
        <div class="right fr clearfix">
          <span class="fl">{{ item.yuanBi }}</span>
          <img class="fl" src="~img/home/Path7@2x.png" alt="" />
        </div>
      </li>
    </ul>
    <div v-if="showMore&&rechangeList&&rechangeList.length > 0" class="nonehint">
      <img src="~img/account/dingdan.png" alt="" />
    </div>
    <nodata class="posCen" v-if="rechangeList && rechangeList.length == 0">
      <div slot="datatitle" class="nodata-tip">暫無消費記錄！</div>
    </nodata>
  </div>
</template>

<script>
import { groupPay } from "apiurl/pay";
export default {
  data() {
    return {
      rechangeList: null,
      pageNum: 0,
      loading: false,
      hasNextPage: true,
      showMore: false
    };
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    gotoup() {
      this.$router.push("/toup");
    },
    async loadMore() {
      if (this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await groupPay({ pageNo: this.pageNum, pageSize: 10 });
        if (res.success) {
          this.hasNextPage = res.data.hasNextPage;
          this.rechangeList = this.rechangeList?[...this.rechangeList, ...res.data.items]:res.data.items;
          console.log(this.rechangeList)
          if (this.rechangeList.length < 10) {
            this.showMore = true;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    },
    goone(id) {
      this.$router.push(`/consume/${id}`);
    }
  }
};
</script>

<style lang="stylus" scoped>
.bgColor
    width 100vw
    min-height: 100vh
    background:rgba(249,249,249,1);
.list
    padding-top 130px
    li
        padding 23px 29px 27px 33px
        margin 0 21px 24px 28px
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 12px 0px rgba(0,0,0,0.07);
        border-radius:24px;
        color #555555
        font-size 26px
        line-height 40px
        .right
            height 97px
            span
                font-size:36px;
                font-weight:600;
                line-height 97px
                color #999
            img
                width 13px
                height 22px
                margin-left 14px
                margin-top 37.5px
        .left
            width 70%
        .type
            font-size:32px;
            margin-bottom 16px
            color #333
        .time
            color #555555
            font-size:26px;
.nonehint
    margin 65px auto 0
    padding-bottom 30px
    width 209px
    img
        width 209px
</style>
